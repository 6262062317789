




















































import { Component, Vue } from "vue-property-decorator";
import MeetingCard from "@/components/meetingCard/MeetingCard.vue";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import LetterIcon from "@/components/letterIcon/LetterIcon.vue";
import FilterComponent from "@/components/filterComponent/Index.vue";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    MeetingCard,
    AppModal,
    LetterIcon,
    FilterComponent
  }
})
export default class Landing extends Vue {
  @Action public getMeetingList: any;
  @Action public getMeetingByGuestUser: any;
  @Action public initMeeting: any;
  @Getter public meeting: any;
  @Getter public isAuthenticated: any;

  ref: any = "appModalRef";
  attendeeList: any = [];

  beforeMount() {
    if (this.$route.params.id) {
      this.getMeetingByGuestUser(this.$route.params.id);
    }
  }

  openAttendeeListModal(list: any): void {
    this.attendeeList = list ? list : [];
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  onLogin() {
    localStorage.setItem("isEmailUser", "true");
    localStorage.setItem("emmailUserMeetingId", this.meeting.id);
    this.$router.push({ name: 'login.index' }).catch();
  }

  destroyed() {
    this.initMeeting();
  }
}
